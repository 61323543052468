import logConstants from '@src/utils/constants/log';
import get from 'lodash/get';
import sumBy from 'lodash/sumBy';
import localize from '@src/i18n';

const foodNameLens = item => get(item, ['food', 'name']);
const amountLens = item => get(item, ['amount']);
const unitLens = item => get(item, ['unit']);
const foodIdLens = item => get(item, ['food', 'id']);
const positionLens = item => get(item, ['position']);
const kindLens = item => get(item, ['food', 'kind']);
const kcalLens = item => get(item, ['kcal']);
const foodItemIdLens = item => get(item, ['foodItemId']);
const quickStoreLens = item => get(item, ['food', 'quickStore']);
const durationMinutesLens = item => get(item, ['durationMinutes']);
const repsLens = item => get(item, ['reps']);
const setsLens = item => get(item, ['sets']);
const kgLens = item => get(item, ['kg']);
const carbsGramsLens = item => get(item, ['carbsGrams']);
const proteinGramsLens = item => get(item, ['proteinGrams']);
const fatGramsLens = item => get(item, ['fatGrams']);
const alcoholGramsLens = item => get(item, ['alcoholGrams']);
const energyPercentageFromFatLens = item =>
  get(item, ['food', 'energyDistribution', 'energyPercentageFromFat']);
const energyPercentageFromCarbsLens = item =>
  get(item, ['food', 'energyDistribution', 'energyPercentageFromCarbs']);
const energyPercentageFromProteinLens = item =>
  get(item, ['food', 'energyDistribution', 'energyPercentageFromProtein']);
const energyPercentageFromAlcoholLens = item =>
  get(item, ['food', 'energyDistribution', 'energyPercentageFromAlcohol']);

const isZero = value => value == 0; // eslint-disable-line eqeqeq

const isLogEmpty = logState => {
  if (
    !logState.breakfastItems.length &&
    !logState.lunchItems.length &&
    !logState.dinnerItems.length &&
    !logState.snackItems.length &&
    !logState.supperItems.length
  ) {
    return true;
  }

  if (
    sumBy(logState.breakfastItems, 'kcal') +
    sumBy(logState.lunchItems, 'kcal') +
    sumBy(logState.dinnerItems, 'kcal') +
    sumBy(logState.snackItems, 'kcal') +
    sumBy(logState.supperItems, 'kcal')
  ) {
    return false;
  }
  return true;
};

// The flag `quickStore` is missing in the response; Set it by checking if
// durationMinutes, reps, sets ang kg all are set to 0
const isExerciseQuickStored = item =>
  isZero(durationMinutesLens(item)) &&
  isZero(repsLens(item)) &&
  isZero(setsLens(item)) &&
  isZero(kgLens(item));

const isRecipe = item => kindLens(item) === 'RECIPE';

const getFoodLog = foodItems =>
  foodItems.map(item => ({
    name: foodNameLens(item),
    amount: amountLens(item),
    unit: unitLens(item),
    foodId: foodIdLens(item),
    position: positionLens(item),
    isRecipe: isRecipe(item),
    kcal: kcalLens(item),
    foodItemId: foodItemIdLens(item),
    quickStore: quickStoreLens(item)
  }));

const getExerciseLog = exerciseItems =>
  exerciseItems.map(item => ({
    ...item,
    quickStore: isExerciseQuickStored(item),
    quickLogType: 'exercise'
  }));

const getNutritionWeight = (
  breakfastItems,
  lunchItems,
  dinnerItems,
  snackItems,
  supperItems,
  recommendedKcal,
  preciseUnit = false
) => {
  const foodItems = breakfastItems?.concat(
    lunchItems,
    dinnerItems,
    snackItems,
    supperItems
  );

  if (!foodItems?.length) {
    return [];
  }

  const macronutrients = foodItems.map(item => ({
    fat: fatGramsLens(item),
    carbs: carbsGramsLens(item),
    protein: proteinGramsLens(item),
    alcohol: alcoholGramsLens(item)
  }));

  return ['fat', 'carbs', 'protein', 'alcohol'].map(item => {
    const MIN = `${getRecommended(
      recommendedKcal,
      `MIN_RECOMMENDED_${item.toUpperCase()}_PERCENT`
    )}`;
    const MAX = `${getRecommended(
      recommendedKcal,
      `MAX_RECOMMENDED_${item.toUpperCase()}_PERCENT`
    )}`;
    const KCAL_PER_GRAM = logConstants[`KCAL_PER_GRAM_${item.toUpperCase()}`];
    const unitSum = sumBy(macronutrients, item);

    return {
      id: nutritionWeightId[`${item}Grams`],
      name: getNutritionWeightName(`${item}Grams`),
      // Ceil instead of round in order to keep consistence with kcalories chart.
      // Otherwise, there can be a corner case when 300.46 grams of fat are rounded to 300, and are in the recommended limit 300g
      // but kcals are not the recommended limit (small differences in grams are multiplied when computing kcals)
      unit: (preciseUnit && unitSum) || Math.ceil(unitSum),
      min: KCAL_PER_GRAM && Math.round(MIN / KCAL_PER_GRAM),
      max: KCAL_PER_GRAM && Math.round(MAX / KCAL_PER_GRAM),
      visible: !(item === 'alcohol' && !unitSum)
    };
  });
};

const getNutritionWeightDefault = recommendedKcal => {
  if (!recommendedKcal) {
    return [];
  }

  return ['fat', 'carbs', 'protein'].map(item => {
    const MIN = `${getRecommended(
      recommendedKcal,
      `MIN_RECOMMENDED_${item.toUpperCase()}_PERCENT`
    )}`;
    const MAX = `${getRecommended(
      recommendedKcal,
      `MAX_RECOMMENDED_${item.toUpperCase()}_PERCENT`
    )}`;
    const KCAL_PER_GRAM = logConstants[`KCAL_PER_GRAM_${item.toUpperCase()}`];
    const MIN_KCAL_PER_GRAM = KCAL_PER_GRAM && Math.round(MIN / KCAL_PER_GRAM);
    const MAX_KCAL_PER_GRAM = KCAL_PER_GRAM && Math.round(MAX / KCAL_PER_GRAM);

    return {
      id: nutritionWeightId[`${item}Grams`],
      name: getNutritionWeightName(`${item}Grams`),
      unit: '-',
      unitDefault: (MIN_KCAL_PER_GRAM + MAX_KCAL_PER_GRAM) / 2,
      min: MIN_KCAL_PER_GRAM,
      max: MAX_KCAL_PER_GRAM,
      visible: true
    };
  });
};

const getNutritionPercentage = (
  breakfastItems,
  lunchItems,
  dinnerItems,
  snackItems,
  supperItems = [],
  recommendedKcal,
  preciseUnit = false
) => {
  const foodItems = breakfastItems?.concat(
    lunchItems,
    dinnerItems,
    snackItems,
    supperItems
  );

  if (!foodItems?.length) {
    return [];
  }

  const energyDistribution = foodItems.map(item => ({
    fatKcal: getKcal(energyPercentageFromFatLens(item), kcalLens(item)),
    carbsKcal: getKcal(energyPercentageFromCarbsLens(item), kcalLens(item)),
    proteinKcal: getKcal(energyPercentageFromProteinLens(item), kcalLens(item)),
    alcoholKcal: getKcal(energyPercentageFromAlcoholLens(item), kcalLens(item))
  }));

  const returnPreciseWeights = true;
  const nutritionWeights = getNutritionWeight(
    breakfastItems,
    lunchItems,
    dinnerItems,
    snackItems,
    supperItems,
    recommendedKcal,
    returnPreciseWeights
  );

  return ['fat', 'carbs', 'protein', 'alcohol'].map(item => {
    const unitSum = sumBy(energyDistribution, `${item}Kcal`);
    const { MIN, MAX } = getBothAdjustedRecommended(
      recommendedKcal,
      nutritionWeights,
      unitSum,
      item
    );

    return {
      id: nutritionPercentageId[`${item}Kcal`],
      name: getNutritionPercentagetName(`${item}Kcal`),
      unit: (preciseUnit && unitSum) || Math.round(unitSum),
      min: MIN,
      max: MAX,
      visible: !(item === 'alcohol' && !unitSum)
    };
  });
};

const getNutritionPercentageDefault = (
  breakfastItems,
  lunchItems,
  dinnerItems,
  snackItems,
  supperItems,
  recommendedKcal
) => {
  if (!recommendedKcal) {
    return [];
  }

  const returnPreciseValue = true;
  const nutritionWeights = getNutritionWeight(
    breakfastItems,
    lunchItems,
    dinnerItems,
    snackItems,
    supperItems,
    recommendedKcal,
    returnPreciseValue
  );

  const nutritionPercentageKcals = getNutritionPercentage(
    breakfastItems,
    lunchItems,
    dinnerItems,
    snackItems,
    supperItems,
    recommendedKcal,
    returnPreciseValue
  );

  return ['fat', 'carbs', 'protein'].map(item => {
    const percentageKcalDetails = nutritionPercentageKcals.filter(
      ({ id }) => id === nutritionPercentageId[`${item}Kcal`]
    );
    const nutritionPercentageKcal =
      percentageKcalDetails.length && percentageKcalDetails[0].unit;

    const { MIN, MAX } = getBothAdjustedRecommended(
      recommendedKcal,
      nutritionWeights,
      nutritionPercentageKcal,
      item
    );

    return {
      id: nutritionPercentageId[`${item}Kcal`],
      name: getNutritionPercentagetName(`${item}Kcal`),
      unit: '-',
      unitDefault: (MIN + MAX) / 2,
      min: MIN,
      max: MAX,
      visible: true
    };
  });
};

const getRecommended = (recommendedKcal, key) =>
  logConstants[key] && recommendedKcal * logConstants[key];

const getBothAdjustedRecommended = (
  recommendedKcal,
  nutritionWeights,
  nutritionPercentageKcal,
  item
) => {
  const MIN = getAdjustedRecommended(
    recommendedKcal,
    nutritionWeights,
    nutritionPercentageKcal,
    item,
    'MIN'
  );
  const MAX = getAdjustedRecommended(
    recommendedKcal,
    nutritionWeights,
    nutritionPercentageKcal,
    item,
    'MAX'
  );
  return { MIN, MAX };
};

/**
 * The boundary is adjusted because energyPercentageFromFatLens() returns not percent
 * of fat in kcalLens() but fat percent in kcals computed as:
 * proteinGrams * 4 + fatGrams * 9 + carbsGrams * 4 + alcoholGrams * 7
 * and these two numbers differ very often.
 */
const getAdjustedRecommended = (
  recommendedKcalPerDay,
  nutritionWeights,
  nutritionPercentageKcalPerItem,
  item,
  boundaryType
) => {
  const recommendedKcal = getRecommended(
    recommendedKcalPerDay,
    `${boundaryType}_RECOMMENDED_${item.toUpperCase()}_PERCENT`
  );
  if (!recommendedKcal) {
    return recommendedKcal;
  }
  if (!nutritionWeights.length || !nutritionPercentageKcalPerItem) {
    return Math.round(recommendedKcal);
  }
  const kcalPerGram = logConstants[`KCAL_PER_GRAM_${item.toUpperCase()}`];
  const weightDetails = nutritionWeights.filter(
    ({ id }) => id === nutritionWeightId[`${item}Grams`]
  );
  const weight = weightDetails.length && weightDetails[0].unit;
  if (!weight) {
    return recommendedKcal;
  }
  return Math.round(
    (recommendedKcal * nutritionPercentageKcalPerItem) / (kcalPerGram * weight)
  );
};

const getKcal = (energyPercentage, kcal) =>
  (Math.round((energyPercentage * 1000) / 100) / 1000) * kcal;

const nutritionPercentageId = {
  fatKcal: 'fat',
  carbsKcal: 'carbs',
  proteinKcal: 'protein',
  alcoholKcal: 'alcohol'
};

const getNutritionPercentagetName = tag => {
  const nutritionPercentagetName = {
    fatKcal: localize('label_nutrition_fat'),
    carbsKcal: localize('label_nutrition_carbohydrates'),
    proteinKcal: localize('label_nutrition_protein'),
    alcoholKcal: localize('label_nutrition_alcohol')
  };

  return nutritionPercentagetName[tag];
};

const nutritionWeightId = {
  fatGrams: 'fat',
  carbsGrams: 'carbs',
  proteinGrams: 'protein',
  alcoholGrams: 'alcohol'
};

const getNutritionWeightName = tag => {
  const nutritionWeightName = {
    fatGrams: localize('label_nutrition_fat'),
    carbsGrams: localize('label_nutrition_carbohydrates'),
    proteinGrams: localize('label_nutrition_protein'),
    alcoholGrams: localize('label_nutrition_alcohol')
  };

  return nutritionWeightName[tag];
};

export default {
  getFoodLog,
  getExerciseLog,
  isExerciseQuickStored,
  getNutritionWeight,
  getNutritionWeightDefault,
  getNutritionPercentage,
  getNutritionPercentageDefault,
  isLogEmpty
};
